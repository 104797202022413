import React from 'react'
import { AboutUs, Categories, Footer, Header, Main, MobileApp, Offers, ResponsibleGaming } from '../../Components'

function Home() {
  return (
    <div>
    <Header/>
    <Main/>
    {/*<Categories/>*/}
    {/*<Offers/>*/}
    {/*<MobileApp/>*/}
    
    {/*<Footer/>*/}
    </div>
  )
}

export default Home
