import React from 'react'
import { dice, mainBg } from '../../assets/images'



function Main() {

  return (
    <div class="main-banner" id="top">
       
    <div class="container">
     {/*<img src={dice} alt=""  height={180} width={250} />*/}
      <div class="row">
        <div class="col-lg-7">

          <div class="caption header-text">
            <h6>Experience the Ultimate Cricket news hub</h6>
            <div class="line-dec"></div>
            <h4>Dive <em>into The </em> world of Cricket news <span>only on Unicon365.</span></h4>
            <p>The most leading news website in India.</p>
            <div class="main-button scroll-to-section">
              <a href="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/UNICONBET1.1.0.apk">Download App</a>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Main
