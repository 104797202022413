import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/unicon-logo.png';


function Header() {
  return (
    <div id='navigation'>{/* <!-- navigation section --> */}

    <Navbar expand="lg">
      <Container fluid className='d-flex justify-content-between align-items-center'>
        <Navbar.Brand href="https://11exch.com/" target='_blank'><img style={{height: "45px"}} src={logo} alt='logo' height={30}/></Navbar.Brand>
       
        <div className='d-flex justify-content-end align-items-center gap-2'>
              <a href='https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/UNICONBET1.1.0.apk' target='_blank' className='btn btn-outline btn-outline-light'>Download App</a>
              <a href='https://unicon365.com/register' target='_blank' className='btn text-light' style={{backgroundColor:"#d5253a"}}>Register</a>
           
            </div>
      </Container>
    </Navbar>
</div>
  )
}

export default Header
